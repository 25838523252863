











































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class ConnectIcon extends Vue {
  @Prop()
  private iconType!:
    | 'star'
    | 'alert'
    | 'info'
    | 'announcement'
    | 'loading'
    | 'outline_lightbulb'
    | 'pencil_box'
    | 'help'
    | 'trash_box'
    | 'new_tab'
    | 'dot'
    | 'right_arrow'
    | 'menu-horizontal'
    | 'open-new-tab'
    | 'anonymous-user'
    | 'anonymous-users'
    | 'building'
    | 'chat_outline'
    | 'heart'
    | 'heart_outline'
    | 'arrow-simple-right'
    | 'outline-lightbulb'
    | 'check-symbol'
    | 'left_arrow'
    | 'construction'
    | 'baseline-map'
    | 'copy'
    | 'close'
    | 'outline-chat'
    | 'help-outline'
    | 'right-mdi-arrow'
    | 'night-sleep'
    | 'base-clock'
    | 'check'
    | 'x'
    | 'warning'
    | 'plus'
    | 'user'
    | 'trash'
    | 'trash-fill'
    | 'send'
    | 'right-arrow'
    | 'close-circle'
    | 'download'
    | 'attention'

  @Prop()
  private fill!: string

  @Prop({ default: 16 })
  private size!: number

  get width() {
    switch (this.iconType) {
      case 'help':
        return this.size + 1
      default:
        return this.size
    }
  }

  get height() {
    switch (this.iconType) {
      case 'open-new-tab':
      case 'plus':
      case 'right-arrow':
        return this.size + 1
      case 'help':
        return this.size
      default:
        return this.size
    }
  }

  get viewBox() {
    switch (this.iconType) {
      case 'help':
        return `0 0 ${this.size + 1} ${this.size}`
      case 'right-arrow':
      case 'open-new-tab':
        return `0 0 ${this.size} ${this.size + 1}`
      default:
        return `0 0 ${this.size} ${this.size}`
    }
  }
}
