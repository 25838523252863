import { render, staticRenderFns } from "./ConnectV2Radio.vue?vue&type=template&id=212802aa&scoped=true&"
import script from "./ConnectV2Radio.vue?vue&type=script&lang=ts&"
export * from "./ConnectV2Radio.vue?vue&type=script&lang=ts&"
import style0 from "./ConnectV2Radio.vue?vue&type=style&index=0&id=212802aa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212802aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/var/www/kakutoku_connect/nuxt/src/components/Form/Input.vue').default})
