import cloneDeep from 'lodash.clonedeep'
import { Context } from '@nuxt/types'
import { LockStore, ReadsStore, ThreadDetailsStore } from '~/store'
import IThreadDetail from '~/Interfaces/Message/IThreadDetail'

export default class MessagePeriodicCheckService {
  private readonly context: Context
  private setIntervalId: number | undefined = undefined
  private url: string | undefined = undefined

  constructor(context: Context) {
    this.context = context
  }

  fire(
    threadHashId: string,
    threadDetails: IThreadDetail[],
    scrollAction?: () => void
  ) {
    const threadDetailsCloned = cloneDeep(threadDetails)
    if (threadDetailsCloned) {
      const lastThreadDetailId = threadDetailsCloned.sort(
        (a: IThreadDetail, b: IThreadDetail) =>
          b.threadDetailId - a.threadDetailId
      )[0]?.threadDetailId

      this.periodicCheckNewDetail(
        threadHashId,
        lastThreadDetailId ?? 0,
        scrollAction
      )
    }
  }

  clear() {
    clearInterval(this.setIntervalId)
    this.url = undefined
  }

  private async periodicCheckNewDetail(
    threadHashId: string,
    lastThreadDetailId: number,
    scrollAction?: () => void
  ) {
    if (!this.url) {
      this.url = await this.getLastDetailIdUrl(threadHashId)
    }

    console.log(
      'THREAD_CHECK_INTERVAL_SECONDS: ' +
        this.context.$config.THREAD_CHECK_INTERVAL_SECONDS
    )
    const intervalSecond =
      this.context.$config.THREAD_CHECK_INTERVAL_SECONDS ?? 5

    const self = this
    this.setIntervalId = Number(
      setInterval(async () => {
        const id = await self.getLastDetailId(self.url!)

        if (id && id > lastThreadDetailId) {
          const newDetails = await self.getNewDetail(
            threadHashId,
            lastThreadDetailId
          )
          if (newDetails.length === 0) {
            return
          }

          ThreadDetailsStore.addThreadDetail(newDetails)
          self.clear()

          self.fire(threadHashId, newDetails!, scrollAction)

          if (scrollAction) {
            scrollAction()
          }

          const couldRead = await self.context.$services.message.postRead(
            threadHashId
          )
          if (couldRead) {
            ReadsStore.setIsRead(threadHashId)
          }
        }
      }, intervalSecond * 1000)
    )
  }

  async getLastDetailIdUrl(threadHashId: string): Promise<string> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.context.$axios.get(
        'api/thread/connect_client/last_detail_url/' + threadHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.url))
    } catch (err) {
      console.log(err)

      return ''
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  async getLastDetailId(url: string): Promise<number | null> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await fetch(url, { cache: 'no-store' })
      // console.log(response)

      const json = (await response.json()) as { id: number | null }

      return json.id
    } catch (err) {
      console.log(err)

      return 0
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  async getNewDetail(
    threadHashId: string,
    threadDetailId: number
  ): Promise<IThreadDetail[]> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.context.$axios.get(
        'api/thread/connect_client/new_detail/' +
          threadHashId +
          '/' +
          threadDetailId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.details))
    } catch (err) {
      console.log(err)

      return []
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }
}
