





























































import {Component, Emit, Prop, Vue} from "nuxt-property-decorator";
import VueCropper from 'vue-cropperjs'

import { ConnectCompanyStore, ModalStore } from "~/utils/store-accessor";
import Modal from "~/components/Modal.vue";
import CropImage from "~/components/common/profile/connectCompany/crop-image";
import 'cropperjs/dist/cropper.css';

@Component({
  components: { Modal, VueCropper }
})
export default class ProfileConnectCompanyMemberImageModal extends Vue{
  private imageSrc: string | ArrayBuffer | undefined | null = null;

  mounted() {
    this.imageSrc = null
  }

  private closeModal()
  {
    ModalStore.hide('ProfileConnectCompanyMemberImageModal')
    this.$nextTick(() => {
      this.imageSrc = null
    })
  }

  get shouldDisplay():boolean
  {
    return ModalStore.shouldDisplay('ProfileConnectCompanyMemberImageModal')
  }

  private cancel(e: Event)
  {
    this.closeModal()
  }

  private inputFile(e: Event) {
    console.log(e);
    const target = <any>e.target

    const file = <File>target.files[0]

    console.log(file)
    this.setImage(file)
  }

  private async setImage(file: File) {
    const cropImage =  new CropImage(file)
    // @MEMO: reactiveにするため一度nullを代入
    this.imageSrc = null;
    this.imageSrc = await cropImage.getImage()
  }

  private submit()
  {
    if(!this.imageSrc) {
      return
    }

    // @ts-ignore
    this.$refs.cropper.getCroppedCanvas({
      width: 180,
      height: 180,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      fillColor: 'var(--k-color-solid-white)',
    }).toBlob(async (blob: Blob) => {
      const formData = new FormData();
      formData.append('upload_image', blob);

      console.log(blob);
      this.passImage(blob)
    })

    this.closeModal()
  }

  @Emit()
  private passImage(blob: Blob) {

  }
}
