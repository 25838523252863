import { render, staticRenderFns } from "./SupportItemButton.vue?vue&type=template&id=b697c676&scoped=true&"
import script from "./SupportItemButton.vue?vue&type=script&lang=ts&"
export * from "./SupportItemButton.vue?vue&type=script&lang=ts&"
import style0 from "./SupportItemButton.vue?vue&type=style&index=0&id=b697c676&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b697c676",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConnectInput: require('/var/www/kakutoku_connect/nuxt/src/components/common/components/ConnectInput.vue').default})
