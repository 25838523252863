import { render, staticRenderFns } from "./index.vue?vue&type=template&id=35f156c7&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&module=s&"
import style1 from "./index.vue?vue&type=style&index=1&id=35f156c7&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "35f156c7",
  null
  
)

export default component.exports