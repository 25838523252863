


























import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { IConnectProposal } from '~/Interfaces'
import ClientMessageAttentionModal from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientMessageAttentionModal/index.vue'
import ClientProposalDetailTitle from '~/components/Client/ClientProposal/ClientProposalDetail/components/ClientProposalDetailTitle/index.vue'
import {
  ConnectProposalThreadStore,
  ModalStore,
  ReadsStore,
  ThreadDetailsStore,
  ThreadGroupsStore,
  TopicStore,
  UploadFileStore,
} from '~/utils/store-accessor'
import ThreadForm from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/message/ThreadForm.vue'
import ThreadFormFileUpload from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/message/ThreadFormFileUpload.vue'
import ThreadMessages from '~/components/Client/ClientProposal/ClientProposalDetailVersion2/message/ThreadMessages.vue'

@Component({
  components: {
    ThreadMessages,
    ThreadFormFileUpload,
    ThreadForm,
    ClientMessageAttentionModal,
    ClientProposalDetailTitle,
  },
})
export default class ClientProposalDetailProposalMessage extends Vue {
  @Prop()
  private connectProposal!: IConnectProposal

  @Prop({ default: true })
  private willAppearClientMessageAttentionModal!: boolean

  private threadGroupHashId!: string
  private isConnect!: boolean
  private isLocked: boolean = false

  // メッセージの読み込み完了をemitするためにasync/awaitを追加
  async mounted() {
    await this.load()
    this.onMessagesLoaded()
    this.scrollBottom(undefined, 'auto')
  }

  destroyed() {
    this.$services.messagePeriodicCheckService.clear()
  }

  async load(force: boolean = false) {
    const messageService = this.$services.message

    if (!ThreadGroupsStore.getThreadGroups.length || force) {
      const groups = await messageService.getGroups()
      if (groups) {
        ThreadGroupsStore.setThreadGroups(groups.thread_groups)

        ReadsStore.setReads(groups.reads)
      }
    }

    if (['waiting', 'pending'].includes(this.connectProposalStatus)) {
      return
    }

    let threadHashId = ConnectProposalThreadStore.getThreadHashId(
      this.connectProposal.connectProposalHashId
    )

    if (!threadHashId) {
      threadHashId = await messageService.findThreadHashId(
        this.connectProposal.connectProposalHashId,
        true
      )
    }

    if (!threadHashId) return

    // threadHashId を cache
    ConnectProposalThreadStore.setThreadHashId({
      connectProposalHashId: this.connectProposal.connectProposalHashId,
      threadHashId,
    })

    const couldRead = await messageService.postRead(threadHashId)
    if (couldRead) {
      ReadsStore.setIsRead(threadHashId)
    }

    const topic = await messageService.getTopic(threadHashId)
    TopicStore.setTopic(topic)

    ThreadDetailsStore.setThreadDetails(topic?.thread.threadDetails)

    if (this.willAppearClientMessageAttentionModal) {
      this.checkAttention()
    }

    this.$services.messagePeriodicCheckService.fire(
      threadHashId,
      topic?.thread.threadDetails ?? [],
      () => {
        this.scrollBottom(undefined, 'auto')
      }
    )
  }

  private checkAttention() {
    if (!TopicStore.getTopic?.thread.isAgreedMessageAttentionToClient) {
      ModalStore.show(
        'clientMessageAttentionModal' +
          this.connectProposal.connectProposalHashId
      )
    }
  }

  get connectProposalStatus(): string {
    return this.connectProposal.connectProposalStatus as any
  }

  get disabledType(): string | null {
    if (
      ['rejected', 'rejected_not_correspond'].includes(
        this.connectProposalStatus
      )
    ) {
      return 'rejected'
    }
    return null
  }

  private get isSupportAccount(): boolean {
    return (
      this.$services.user.isSupportClient ||
      this.$services.user.isSupportPartner
    )
  }

  get isOpenedFileUpload(): boolean {
    return UploadFileStore.getIsOpened
  }

  private scrollBottom(
    event: Event | undefined,
    behavior: 'smooth' | 'auto' = 'smooth'
  ) {
    this.$nextTick(() => {
      const threadMessages = this.$refs.messages as ThreadMessages
      ;(threadMessages.$refs
        .threadMessagesContainer as HTMLDivElement).scrollIntoView({
        behavior,
        block: 'end',
      })
    })
  }

  private changeTextareaHeight() {
    this.$nextTick(() => {
      const form = this.$refs.form as Vue
      const threadForm = form.$refs.threadForm as HTMLDivElement

      const messages = this.$refs.messages as Vue
      const threadMessages = messages.$refs.threadMessages as HTMLDivElement
      threadMessages.style.height =
        'calc(100% - ' + threadForm.scrollHeight + 'px)'
    })
  }

  /**
   * 商談する(実行)
   */
  async executeNegotiate() {
    // 住所登録をしていない場合は、処理を停止しモーダルで登録を促す
    if (this.$services.user.shouldRegisterAddress) {
      ModalStore.show('ClientUrgeProfileModal')
      return
    }

    this.isLocked = true
    const proposal = await this.$services.proposal.negotiate(
      this.connectProposal.connectProposalHashId
    )

    await this.openThread()
    this.isLocked = false
    if (proposal) {
      this.connectProposal.connectProposalStatus =
        proposal.connectProposalStatus
    }
    await this.load()
  }

  async reject() {
    const proposal = await this.$services.proposal.reject(
      this.connectProposal.connectProposalHashId
    )
    if (proposal) {
      this.connectProposal.connectProposalStatus =
        proposal.connectProposalStatus
    }
  }

  private async openThread() {
    await this.$services.message.openThread(
      this.connectProposal.connectProposalHashId
    )
    this.$auth.fetchUser()
  }

  @Emit('refreshProposals')
  private refreshProposals() {}

  @Emit('onMessagesLoaded')
  private onMessagesLoaded() {}
}
