import { render, staticRenderFns } from "./index.vue?vue&type=template&id=38a85945&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConnectV2InputLabel: require('/var/www/kakutoku_connect/nuxt/src/components/common/components/v2/ConnectV2InputLabel.vue').default,ConnectV2Input: require('/var/www/kakutoku_connect/nuxt/src/components/common/components/v2/ConnectV2Input.vue').default,ConnectV2Checkbox: require('/var/www/kakutoku_connect/nuxt/src/components/common/components/v2/ConnectV2Checkbox.vue').default,SupportAttention: require('/var/www/kakutoku_connect/nuxt/src/components/common/profile/partner/SupportAttention/index.vue').default})
