







































































































































































































































































































import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'
import { PREFECTURES } from '~/utils'
import ConnectV2Form from '~/components/common/components/v2/ConnectV2Form.vue'
import ConnectV2Checkbox from '~/components/common/components/v2/ConnectV2Checkbox.vue'
import ConnectV2Dropdown from '~/components/common/components/v2/ConnectV2Dropdown.vue'
import SupportAttention from '~/components/common/profile/partner/SupportAttention/index.vue'
import ConnectButton from '~/components/common/components/ConnectButton.vue'
import ConnectV2InputLabel from '~/components/common/components/v2/ConnectV2InputLabel.vue'
import ConnectV2Input from '~/components/common/components/v2/ConnectV2Input.vue'
import ClientTitle from '~/components/common/profile/client/client-title.vue'
import ClientSubTitle from '~/components/common/profile/client/client-sub-title.vue'
import {
  ConnectClientBilling,
  ConnectClientProfile,
  FormValidator,
  Rule,
} from '~/components'
import ConnectIcon from '~/components/common/components/ConnectIcon.vue'

@Component({
  components: {
    ConnectV2Form,
    ConnectV2Checkbox,
    SupportAttention,
    ConnectV2Dropdown,
    ConnectButton,
    ConnectV2InputLabel,
    ConnectV2Input,
    ClientTitle,
    ClientSubTitle,
    ConnectIcon,
  },
})
export default class BillingForm extends Vue {
  @Prop()
  private connectClientBilling!: ConnectClientBilling

  @Prop()
  private connectClientProfile!: ConnectClientProfile

  private emailRules: Rule[] = ['required', 'email']
  private zipCodeRules: Rule[] = ['required', 'max:7']
  private prefectureRules: Rule[] = ['required', 'max:5']
  private cityRules: Rule[] = ['required', 'max:50', 'zenkakuKanjiHiragana']
  private addressRules: Rule[] = ['required', 'max:255']

  mounted() {
    const formValidator = FormValidator.get()
    formValidator.updateFormCustomRule(
      'connectClientBillingDefaultEmail',
      this.emailValidate
    )
  }

  get prefectures() {
    const list = PREFECTURES.map((prefecture) => ({
      label: prefecture,
      value: prefecture,
    }))

    list.unshift({ label: '選択してください', value: '' })

    return list
  }

  private addMail() {
    if (!this.canEditProfile) {
      return
    }

    this.connectClientBilling.emails.push('')
  }

  private deleteMail(index: number) {
    if (!this.canEditProfile) {
      return
    }

    this.connectClientBilling.emails.splice(index, 1)
  }

  private get canEditProfile(): boolean {
    return this.$services.user.getConnectClientAccountRollIsAdmin()
  }

  private emailValidate(): boolean {
    if (this.connectClientBilling.value.shouldUseDefaultEmail) {
      return true
    }

    return this.connectClientBilling.emails.length > 0
  }

  private onShouldUseHeadquartersAddress(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target) {
      return
    }

    if (target.checked) {
      this.connectClientBilling.value.connectClientBillingZipCode = this.connectClientProfile.value.connectClientZipCode
      this.connectClientBilling.value.connectClientBillingPrefecture = this.connectClientProfile.value.connectClientPrefecture
      this.connectClientBilling.value.connectClientBillingCity = this.connectClientProfile.value.connectClientCity
      this.connectClientBilling.value.connectClientBillingAddress = this.connectClientProfile.value.connectClientAddress

      this.zipCodeRules = []
      this.prefectureRules = []
      this.cityRules = []
      this.addressRules = []
    } else {
      this.connectClientBilling.value.connectClientBillingZipCode = ''
      this.connectClientBilling.value.connectClientBillingPrefecture = ''
      this.connectClientBilling.value.connectClientBillingCity = ''
      this.connectClientBilling.value.connectClientBillingAddress = ''

      this.zipCodeRules = ['required', 'max:7']
      this.prefectureRules = ['required', 'max:5']
      this.cityRules = ['required', 'max:50', 'zenkakuKanjiHiragana']
      this.addressRules = ['required', 'max:255']
    }

    const formValidator = FormValidator.get()
    formValidator.updateFormRule(
      'connectClientBillingZipCode',
      this.zipCodeRules
    )
    formValidator.updateFormRule(
      'connectClientBillingPrefecture',
      this.prefectureRules
    )
    formValidator.updateFormRule('connectClientBillingCity', this.cityRules)
    formValidator.updateFormRule(
      'connectClientBillingAddress',
      this.addressRules
    )

    this.connectClientBilling.value.shouldUseHeadquartersAddress =
      target.checked
  }

  private onShouldUseDefaultEmail(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target) {
      return
    }

    this.connectClientBilling.value.shouldUseDefaultEmail = target.checked
  }
}
