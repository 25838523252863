import { render, staticRenderFns } from "./profile-connect-company-career-template-mainachievement.vue?vue&type=template&id=2866f5e1&"
import script from "./profile-connect-company-career-template-mainachievement.vue?vue&type=script&lang=ts&"
export * from "./profile-connect-company-career-template-mainachievement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConnectTextarea: require('/var/www/kakutoku_connect/nuxt/src/components/common/components/ConnectTextarea.vue').default})
