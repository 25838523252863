import { render, staticRenderFns } from "./ConnectV2Snackbar.vue?vue&type=template&id=83bc31d0&"
import script from "./ConnectV2Snackbar.vue?vue&type=script&lang=ts&"
export * from "./ConnectV2Snackbar.vue?vue&type=script&lang=ts&"
import style0 from "./ConnectV2Snackbar.vue?vue&type=style&index=0&lang=scss&module=s&"




function injectStyles (context) {
  
  this["s"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConnectIcon: require('/var/www/kakutoku_connect/nuxt/src/components/common/components/ConnectIcon.vue').default})
